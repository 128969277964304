import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/icon/company.webp";
import Icon2 from "../../../assets/Images/icon/soil.webp";
import Icon3 from "../../../assets/Images/icon/job.webp";
import Icon4 from "../../../assets/Images/icon/Dirhams.webp";
import CountUp from "react-countup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChooseUs() {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
  return (
    <>
      <div className="chooseBanneImg">
        <div className="container">
              <div className="titleFont">
                <h2 className="text-center">LES PARC INDUSTRIELS DE LA CFCIM C’EST :</h2>
              </div>
              <Row>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <LazyLoadImage effect="blur"
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon1}
                    />
                    <h3>
                      <CountUp end={500} duration={2.74} />
                    </h3>
                    <h6>Entreprises</h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <LazyLoadImage effect="blur"
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon2}
                    />
                    <h3>
                      <CountUp end={161} duration={2.75} />
                    </h3>
                    <h6>Hectares</h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <LazyLoadImage effect="blur"
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon3}
                    />
                    <h3>
                      <CountUp end={28000} duration={2.75} />
                    </h3>
                    <h6>Emplois directs </h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <LazyLoadImage effect="blur"
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon4}
                    />
                    <h3>
                      <CountUp end={900} duration={2.75} />
                    </h3>
                    <h6>MDH</h6>
                  </div>
                </Col>
              </Row>
        </div>
      </div>
    </>
  );
}
