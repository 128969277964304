import React from "react";
import { Row, Col } from "react-bootstrap";
import Image1 from '../../../assets/Images/FuturParcs/fes saiss.png'
import Image2 from '../../../assets/Images/FuturParcs/marrakech.png'
import Image3 from '../../../assets/Images/FuturParcs/laayoun.png'
import Image4 from '../../../assets/Images/FuturParcs/dakhla.png'
import Image5 from '../../../assets/Images/FuturParcs/tantan.png'
import { Link } from 'react-scroll'


export default function AmazingExpertise() {
  const handleSetActive = (to)=> {
    console.log(to);
  }
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} className="pe-5">
              <aside className="asideBar">
                <h4 className="fw-bold">
                  Futurs Parcs
                </h4>
                <h6 className="text-secondary mt-5">
                  <i className="fa fa-angle-right me-1" aria-hidden="true"></i>
                  Parc en phase de travaux
                </h6>
                <hr />
                <ul>
                  <li>
                    <Link 
                    activeClass="active" 
                    className="linkScroll" 
                    to="Lmarsa"
                    spy={true} 
                    smooth={true} 
                    duration={500} >
                      Lmarsa à Laayoune
                    </Link>
                  </li>
                  <li>
                      <Link 
                      activeClass="active" 
                      className="linkScroll" 
                      to="BirGuendouz"
                      spy={true} 
                      smooth={true} 
                      duration={500} >
                        BirGuendouz et de Guerguerat
                      </Link>
                  </li>
                </ul>
                {/*  */}
                <h6 className="text-secondary">
                  <i className="fa fa-angle-right me-1" aria-hidden="true"></i>
                  Parc en phase d'étude
                </h6>
                <hr />
                <ul>
                  <li>
                    <Link 
                      activeClass="active" 
                      className="linkScroll" 
                      to="ECOPARC"
                      spy={true} 
                      smooth={true} 
                      duration={500} >
                        Ecoparc De Fes
                      </Link>
                  </li>
                  <li>
                    <Link 
                      activeClass="active" 
                      className="linkScroll" 
                      to="TAMENSOURT"
                      spy={true} 
                      smooth={true} 
                      duration={500} >
                        Tamsensout à Marrakech
                      </Link>
                  </li>
                  <li>
                    <Link 
                      activeClass="active" 
                      className="linkScroll" 
                      to="TANTAN"
                      spy={true} 
                      smooth={true} 
                      duration={500} >
                        Zone D'Actitvites Economiques El Ouatia Tanatan
                      </Link>
                  </li>
                </ul>
              </aside>
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} className="futurparcs">
            <div id="Lmarsa" className="pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-blue">
                    Parc industriel de Lmarsa à Laayoune :
                  </h4>
                  <img  src={Image3} alt='' width="50%"/>
                </div>
                <p className="text-secondary text-justify">
                Ce nouveau parc industriel, d’une superficie de 70 hectares, aura une capacité d’accueil de 150 entreprises <br />
                – La convention de partenariat pour l’accompagnement à la réalisation et à la gestion de ce nouveau parc industriel a été signée lors du forum de partenariat Maroc-France tenu à Laayoune le 3 Novembre 2018 
                – La région a confié la réalisation du projet à son AREP (agence régionale d’exécution des projets ) <br />
                -	Les études architecturales ont été réalisées, la mobilisation du foncier est en cours ; <br />
                – Les travaux sont en phase final ; <br />
                – Les financements seront assurés par l’Etat Marocain ;<br />
                – La CFCIM assurera le rôle de commercialisateur exclusif des lots
                </p>
              </div>
              <div id="BirGuendouz" className="pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-blue">
                    Parcs Industriels et Logistiques deBirGuendouz et de Guerguerat
                  </h4>
                  <img  src={Image4} alt='' width="50%"/>
                </div>
                <p className="text-secondary text-justify">
                Ces deux nouveau parcs industriels et logistiques, d’une superficie de 30 hectares chacun, auront une capacité d’accueil de 150 entreprises <br />
                – La convention de partenariat pour l’accompagnement à la réalisation et à la gestion de ces deux nouveaux parcs a été signée lors du forum de partenariat Maroc-France tenu à Dakhla le 24 OCTOBRE 2019.<br />
                – Les études architecturales et techniques ont été réalisées ; le marché des travaux de VRD a été attribué fin Juin 2021.<br />
                – Les travaux ont effectivement démarrés en Novembre 2021 <br />
                – La CFCIM assurera un rôle d’accompagnement technique et commercial des projets
                </p>
              </div>

            

              <div id="ECOPARC" className="pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-blue">
                    Ecoparc de Fes
                  </h4>
                  <img  src={Image1} alt='' width="50%"/>
                </div>
                <p className="text-secondary text-justify">
                  Ce nouveau parc industriel, d’une superficie de 20 hectares, aura une capacité d’accueil de 130 entreprises 
                  <br /> - Le terrain se trouve à proximité de l’aéroport FES-SAISS 
                  <br /> - La convention de partenariat pour la réalisation et la gestion de ce nouveau parc industriel a été signée le 24 MAI 2021 
                  <br /> - Une structure de gestion adhoc sera créée avec les partenaires institutionnels 
                </p>
              </div>

              <div id="TAMENSOURT" className="pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-blue">
                    Parc Industriel de TAMENSOURT à MARRAKECH :
                  </h4>
                  <img  src={Image2} alt='' width="50%"/>
                </div>
                <p className="text-secondary text-justify">
                Ce nouveau parc industriel, d’une superficie de 40 hectares, est en cours d’études ; <br />
                - Le terrain se trouve à proximité de la ville de TAMENSOURT, et au bord de l’autoroute d’Agadir ; <br />
                -La convention cadre pour l’accompagnement à la réalisation et à la gestion de ce nouveau parc industriel a été signée entre la CFCIM et le conseil régional MARRAKECH SAFI <br />
                -Les études techniques ont été entamées ; <br />
                -La région a confié la réalisation du projet à son AREP (agence régionale d’exécution des projets ) <br />
                -La CFCIM assurera un rôle d’accompagnement technique et commercial du projet
                </p>
              </div>
              <div id="TANTAN" className="pt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-blue">
                      Zone D'Actitvites Economiques El Ouatia Tanatan
                  </h4>
                  <img  src={Image5} alt='' width="50%"/>
                </div>
                <p className="text-secondary text-justify">
                La région de Guelmim Oued Noun, envisage de réaliser une zone d’activités économiques de 65 Hectares à El Ouatia, près du port de TAN TAN
                <br />
                La CFCIM assurera un rôle d’accompagnement technique en phase de réalisation du projet ; puis de commercialisateur et de gestionnaire de la zone.
                </p>
              </div>



            </Col>
            
          </Row>
        </div>
      </div>
    </>
  );
}
