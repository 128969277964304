import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/ThemeLogo.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  return (
    <>
      <div className="footer">
        <Container>
          <Row className="Footerbox justify-content-between">
            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <LazyLoadImage effect="blur"
                  src={ThemeLogo}
                  alt="Chambre Française de Commerce et d'Industrie du Maroc"
                  width="220px"
                  className="img-fluid mx-auto WLogo"
                />
                  <ul className="list-unstyled social-icon">
                  <li className="d-inline-block">
                    <a href="https://www.facebook.com/CFCIM.Maroc/" aria-label="Facebook" target="_blank" rel="noreferrer" className="d-block">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.linkedin.com/company/cfcim/" aria-label="Linkedin" target="_blank" rel="noreferrer" className="d-block">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.facebook.com/CFCIM.Maroc/" aria-label="Twitter" target="_blank" rel="noreferrer" className="d-block">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.facebook.com/CFCIM.Maroc/" aria-label="Instagram" target="_blank" rel="noreferrer" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3}>
              <div className="F-col-2 footer-text FooterVertical">
                <h3 className=" footerLinkHeading">
                  PROJETS CONÇUS
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/parc-industriel-de-bouskoura">PARC INDUSTRIEL DE BOUSKOURA</Link>
                    </li>
                    <li>
                      <Link to="/parc-industriel-de-ouled-saleh">PARC INDUSTRIEL OULED SALEH</Link>
                    </li>
                    <li>
                      <Link to="/parc-industriel-de-settat">PARC INDUSTRIEL SETTAT</Link>
                    </li>
                    <li>
                      <Link to="/parc-ecologique-de-berrechid">PARC INDUSTRIEL BERRECHID</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
          
            <Col xl={4} lg={4} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading">Contactez Nous 
                <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button></h3>
                  <Collapse in={Aopen}>
                <div className="quickContact">
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>
                    ECOPARC DE BERRECHID, RR318 commune de Sidi El Mekki
                    </span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+212(0)521883188</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>contact@cfcim.org</span>
                  </div>
                </div>
                </Collapse>
              
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">Copyright © 2022 CFCIM . Tous les droits sont réservés.</h6>
      </div>
    </>
  );
};
export default Footer;
