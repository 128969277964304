import React from "react";
import Slider from "react-slick";
import Why from "../../../assets/Images/Why/EcoparcBerrchid.webp";
import { Row, Col } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  aroow: true,
  infinite: true,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:0,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
function PeopleSay() {
  return (
    <>
      <div className="spacing">
        <span className="text-center d-block subtitleBerrechid mb-3 fw-bold">L’ECOPARC DE BERRECHID</span>
      <h2 className="text-center fs-1 fw-bold">1er Parc Écologique au Maroc</h2>
        <p className="text-center mt-3">Un espace de haute qualité environnementale.</p>
        <div className="peopleSayBgColor">
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col xs={12} lg={4} md={4} sm={12} className="p-0 BlcokImageBeerchid">
                <LazyLoadImage layout="raw" effect="blur" className="imageBerrchidSlide" src={Why} alt="L’ECOPARC DE BERRECHID 1er Parc Écologique au Maroc" width="90%" />
              </Col>
              <Col xs={12} lg={7} md={7} sm={12}>
                <Slider {...settings}>
                  <div className="peopleSay text-center">
                    <div className="peopleDetails">
                      <h3 className="fs-2">Bienvenue à l'Ecoparc de Berrechid :</h3>
                      <p>
                      Ce nouveau Parc écologique est le fruit d’un partenariat entre la CFCIM et l’Etat Marocain, représenté par le Ministère de l’Industrie, de l’Investissement, du Commerce et de l’Economie numérique, le Ministère de l’Intérieur, ainsi que la Commune de Sidi el Mekki à Berrechid. Tirant profit d’une longue expérience réussie en matière d’aménagement et de gestion des parcs industriels locatifs, l’Ecoparc de Berrechid ambitionne de franchir un nouveau cap dans l’évolution des zones industrielles durables au Maroc.
                      </p>
                    </div>
                  </div>
                  <div className="peopleSay text-center">
                    <div className="peopleDetails">
                      <h3 className="fs-2">Pourquoi L'Ecoparc de Berrechid :</h3>
                      <p>
                      Ce nouveau parc industriel favorise la croissance économique et la création d’emplois en encourageant le développement de pratiques plus respectueuses de l’environnement. Il revisite l’espace et l’inscrit dans une perspective de développement durable pour maximiser l’impact positif attendu sur le territoire et sur l’économie locale. Il est conçu et aménagé pour offrir aux industries propres un nouveau cadre de travail favorable à la croissance de leurs entreprises et à l’épanouissement de leurs salariés.
                      </p>
                    </div>
                  </div>
                </Slider>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
