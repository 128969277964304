import React, { Component } from "react";
import Slider from "react-slick";
import BrandLogo1 from "../../../assets/Images/Berrechid/Bank_of_africa-logo-2.png";
import BrandLogo2 from "../../../assets/Images/Berrechid/bmci.jpg";
import BrandLogo3 from "../../../assets/Images/Berrechid/societe-generale-logo_2-1024x576.png";
import BrandLogo4 from "../../../assets/Images/Berrechid/xCredit_du_MAROC.png.pagespeed.ic_.M8yck0wbun-1024x207.png";
import BrandLogo5 from "../../../assets/Images/Berrechid/1280px-Logo_AWB.svg-1-1024x329.png";
const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrow:false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,

      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,

      }
    },
    { 
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
};
export default class Brandlogo extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="brandLogo">
          <Slider {...settings}>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo1} alt="BrandLogo" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo2} alt="BrandLogo" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo3} alt="BrandLogo" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo4} alt="BrandLogo" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo5} alt="BrandLogo" />
            </div>
          </Slider>
          </div>
        </div>
      </>
    );
  }
}
