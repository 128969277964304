import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/logo.png";
import { useState } from "react";
import { Link as ScrolLink  } from 'react-scroll'


const Header = () => {
  const [showDropdownC, setShowDropdownC] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className="header sticky-top">
      <Navbar expand="lg" collapseOnSelect>
<div className="container">
  <div className="inner-header justify-content-between align-items-center">
    <Navbar.Brand href="/">
      <img
        src={ThemeLogo}
        width="265px"
        height="55px"
        alt="Chambre Francaise de commerce et d'industrie du maroc"
        className="img-fluid mx-auto WLogo"
      />
    </Navbar.Brand>
    <Button
      className="btn-navbar navbar-toggle"
      onClick={ToggleSidebar}
    >
      <i className="fa fa-bars" aria-hidden="true"></i>
    </Button>
    <div className={`sidebar ${isOpen === true ? "active" : " "}`}>
      <div id="mySidenav" className="sidenav menu-vertical">
        <div className="close-nav hidden-md hidden-lg hidden-xl  ">
          <div>
            <span>Menu</span>
          </div>
          <div>
            <Button
              className="closebtn pull-right"
              onClick={ToggleSidebar}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
        <div className="navDiv">
          <Nav className="justify-content-center" style={{ flex: 1 }}>
          <Nav.Link as={Link} to="/">Accueil</Nav.Link>
          <Nav.Link as={ScrolLink} href="NotreOffre" to="NotreOffre">Notre offre</Nav.Link>

            {/* <Nav.Link href=""> */}
              {/* <ScrolLink href="NotreOffre" to="NotreOffre">Notre offre</ScrolLink> */}
            {/* </Nav.Link> */}
            <NavDropdownMenu title="Nos Parcs">
                <DropdownSubmenu href="#action/3.7" title="Parcs Industriels" className="pe-3">
                <NavDropdown.Item href="#action/8.1">
                  <Link to="/parc-industriel-de-bouskoura">
                    Parc industriel de Bouskoura
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/8.1">
                   <Link to="/parc-industriel-de-ouled-saleh">
                    Parc industriel de Ouled Saleh
                   </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/8.1">
                    <Link to="/parc-industriel-de-settat">
                     Parc industriel de Settat
                    </Link>              
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/8.1">
                    <Link to="parc-ecologique-de-berrechid">
                      Parc industriel de Berrechid
                    </Link>
                </NavDropdown.Item>
                </DropdownSubmenu>
                  <Nav.Link as={Link} to="/futurs-parcs" className="text-dark">Futurs Parcs</Nav.Link>
            </NavDropdownMenu>
            <Nav.Link as={Link} to="/nos-services">Nos Services</Nav.Link>
            <NavDropdown
              title="Actualités"
              show={showDropdownC}
              onMouseLeave={() => setShowDropdownC(false)}
              onMouseOver={() => setShowDropdownC(true)}
            >
              <NavDropdown.Item href="#evenements">
                <Link to="/evenements">Événements</Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#media">
                <Link to="/media">Média</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
      </div>
    </div>
    <div
      className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
      onClick={ToggleSidebar}
    ></div>
  </div>
</div>
</Navbar>
      </div>
    </>
  );
};
export default Header;
