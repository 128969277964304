import React from "react";
import { Row, Col } from "react-bootstrap";
import AmazingEx from "../../../assets/Images/BOUSKOURA/BOUSKOURA.webp";
import logo from "../../../assets/Images/BOUSKOURA/BOUSKOURAAA.webp";
import References from "./References";
import Actionnariat from "./Actionnariat";
import Maps from "./Maps";
import Links from "../../Links";

export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4}>
             <Links />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8}>
              <div className="amazingColContain">
                <img src={AmazingEx}  width="100%" alt="plan d'aménagement de bouskoura"/>
              </div>
              <div>
                <img src={logo} alt='parc-industriel-de-bouskoura' />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 titleparc">PARC INDUSTRIEL DE BOUSKOURA</h5>
                <p className="text-justify">
                    Premier parc développé par la CFCIM, le Parc Industriel de Bouskoura
                    (PIB) –s’étale sur une surface de 28 hectares, lotis en 104 lots industriels
                    et totalement commercialisés.
                </p>
                <p className="text-justify">
                    Le PIB est devenu la référence en matière de gestion de parcs
                    industriels locatifs au Maroc.
                </p>
              </div>
              <Actionnariat />
              <References />
              <Maps />  
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
