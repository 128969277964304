import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function StartedToday() {
  return (
    <>
      <div className="startedToday">
        <div className="container">
          <div className="startedTodayMainDivFloat">
            <Row className="justify-content-between">
              <Col xs={6} lg={6} md={6} sm={6}>
                <div className="startedTodayMainDiv">
                  <div className="text-white">
                    <span>Pour tout type d'informations</span>
                  </div>
                </div>
              </Col>
              <Col  xs={6} lg={6} md={6} sm={6} >
                <div className="startedEmailBox text-end">
                    <button className="button">
                      <Link to="/service">Contactez nous</Link>
                    </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
