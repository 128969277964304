import React from "react";
import { Row, Col } from "react-bootstrap";
import AmazingEx from "../../../assets/Images/OuledSaleh/PLAN-D-AMENAGEMENT.png";
import logo from "../../../assets/Images/OuledSaleh/OULED-SALAH.png";
import References from "./References";
import More from "./More";
import Actionnariat from "./Actionnariat";
import Maps from "./Maps";
import Links from "../../Links";
export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4}>
              <Links />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8}>
              <div className="amazingColContain">
                <img src={AmazingEx} width="100%" alt="parc Ouled Saleh"/>
              </div>
              <div>
                <img src={logo} alt='parc-industriel-de-bouskoura' className="mt-5" />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 titleparc">PARC INDUSTRIEL DE OULED SALEH</h5>
                <p className="text-justify">
                    Le parc industriel de Ouled Salah (POS) s’étend sur une superficie de 32
                    hectares et dispose d’une capacité de 121 lots industriels locatifs dont
                    38 sont des bâtiments industriels prêts à l’emploi ; en plus de bâtiments
                    de services dédiés.
                </p>
                <p className="text-justify">
                    Ce parc industriel est totalement commercialisé.
                </p>
                <p className="text-justify">
                    Le POS est géré par la société de gestion et d’exploitation du parc
                    industriel de Ouled Salah – SOGEPOS sa : son capital est détenu par les
                    actionnaires suivants
                </p>
              </div>
              <Actionnariat />
              <References />
              <Maps />  
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
