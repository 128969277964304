import React, { useEffect } from "react";
import { Row, Col ,Card, CardImg } from "react-bootstrap";
import Service1 from "../../../assets/Images/Service/1.jpeg"
import Service2 from "../../../assets/Images/Service/2.jpeg"
import Service3 from "../../../assets/Images/Service/3.jpeg"
import Service4 from "../../../assets/Images/Service/4.jpeg"
import Service5 from "../../../assets/Images/Service/5.jpeg"
import Service6 from "../../../assets/Images/Service/6.jpeg"
import Service7 from "../../../assets/Images/Service/7.jpeg"
import Service8 from "../../../assets/Images/Service/8.jpeg"
import Aos from "aos";
import "aos/dist/aos.css";
export default function Innovation() {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="servicesCards">
          <Row>
          <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service8}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                            Salle de sport
                          </Card.Text>
                        </Card.Body>
                      </Card>
          </Col>
          <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service2}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                          Ramassage et tri
                          </Card.Text>
                        </Card.Body>
                      </Card>
          </Col>
          <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
            <Card className="ourTeamCard mb-5 border-0">
              <div className="ourTeamImgBox beffect">
                <div className="bimg">
                  <CardImg
                    src={Service1}
                    atl="ourteam"
                    className="img-fluid mx-auto"
                  />
                </div>
              </div>
              <Card.Body className="cardService">
                <Card.Text className="text-center">
                  Salle de réunion
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service7}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                          Pharmacie 
                          </Card.Text>
                        </Card.Body>
                      </Card>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service6}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                            Banque
                          </Card.Text>
                        </Card.Body>
                      </Card>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service3}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                              Sécurité
                          </Card.Text>
                        </Card.Body>
                      </Card>
                </Col>
             
               
                <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service5}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                          Nettoyage 
                          </Card.Text>
                        </Card.Body>
                      </Card>
                </Col>
        
               
                <Col xl={3} lg={3} md={3} sm={6} xs={6} mt={3}>
                      <Card className="ourTeamCard mb-5 border-0">
                        <div className="ourTeamImgBox beffect">
                          <div className="bimg">
                            <CardImg
                              src={Service4}
                              atl="ourteam"
                              className="img-fluid mx-auto"
                            />
                          </div>
                        </div>
                        <Card.Body className="cardService">
                          <Card.Text className="text-center">
                          Restauration collective
                          </Card.Text>
                        </Card.Body>
                      </Card>
                </Col>
               
          </Row>
        </div>
      </div>
    </>
  );
}
