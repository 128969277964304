import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";
import {Helmet} from "react-helmet";
export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>PARC ECOLOGIQUE DE BERRECHID</title>
        <meta
            name="description"
            content=" Ce nouveau parc industriel, d’une superficie de 61 hectares, a une capacité d’accueil de 192 entreprises. L’ECOPARC DE BERRECHID propose des formules d’aménagement sur mesure permettant de s’adapter au mieux aux besoins actuels et futurs de chaque entreprise, il offre des terrains à construire et des bâtiments prêts à l’emploi en mode locatif"
        />
      </Helmet>
      <div className="otherPageBanner servicesBanner">
        <div className="container" data-aos="fade-down">
          <h6>PARC ECOLOGIQUE DE BERRECHID</h6>
        </div>
      </div>
      <AmazingExpertise />
    </>
  );
}
