import React, { Component } from "react";
import Slider from "react-slick";
import BrandLogo1 from "../../../assets/Images/BOUSKOURA/atlanta.webp";
import BrandLogo2 from "../../../assets/Images/BOUSKOURA/bmci-300x150.webp";
import BrandLogo3 from "../../../assets/Images/BOUSKOURA/CDG.webp";
import BrandLogo4 from "../../../assets/Images/BOUSKOURA/logo-societe-generale.webp";
import BrandLogo5 from "../../../assets/Images/BOUSKOURA/RMA.webp";
import BrandLogo6 from "../../../assets/Images/BOUSKOURA/Saham.webp";
import BrandLogo7 from "../../../assets/Images/BOUSKOURA/CR2DIT DU MAROC.png";
import BrandLogo8 from "../../../assets/Images/BOUSKOURA/CAISSE DES DEPOT.png";
import BrandLogo9 from "../../../assets/Images/BOUSKOURA/COMMUNE BOUSKOURA.png";
const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrow:false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,

      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,

      }
    },
    { 
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
};
export default class Brandlogo extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="brandLogo">
          <Slider {...settings}>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo1} alt="atlanta" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo2} alt="bmci" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo3} alt="CDG" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo4} alt="societe generale" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo5} alt="RMA" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo6} alt="Saham" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo7} alt="CR2DIT DU MAROC" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo8} alt="CAISSE DES DEPOT" />
            </div>
            <div>
              <img className="img-fluid mx-auto" src={BrandLogo9} alt="COMMUNE BOUSKOURA" />
            </div>
          </Slider>
          </div>
        </div>
      </>
    );
  }
}
