import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
// import "./theme/css/index.css";
// import "./theme/css/Faq.css";
import App from "./App";
import DefultScrollToTop from "../src/components/ScrollToTop/DefultScrollToTop";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <DefultScrollToTop />
    <App />
  </BrowserRouter>,
  rootElement
);

