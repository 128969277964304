import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";
import {Helmet} from "react-helmet";

export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>PARC INDUSTRIEL DE SETTAT</title>
        <meta
            name="description"
            content="SETTAPARK dispose d’une capacité d’accueil de 85 entreprises"
        />
      </Helmet>
      <div className="otherPageBanner servicesBanner">
        <div className="container" data-aos="fade-down">
          <h6>
            <span className="fw-bold">
              PARC INDUSTRIEL 
            </span> 
            <br />
            DE SETTAT
            </h6>
        </div>
      </div>
      <AmazingExpertise />
    </>
  );
}
