import React from "react";
import { Row } from "react-bootstrap";
export default function AmazingExpertise() {
  return (
    <>
    <div className="NosServices mt-5">
      <div className="container">
        <div className="amazingExpertise text-center">
          <Row>
              <p>
                  les parcs industriels CFCIM 
              </p>
              <p>
                  inclut dans leurs offre une palette de services qui vous permet de gagner du temps en vous proposant diverses fonctions pratiques avec un focus sur la simplicité et l’efficacité au quotidien
              </p>
              {/* <p>
                notamment à travers la mise en place d’un guichet unique afin de vous faciliter les démarches administratives incluant diverses prestations d’aide à la création d’entreprise, d’aide à l’obtention des autorisations de construire, de conseil et suivi des chantiers.
              </p> */}
          </Row>
        </div>
      </div>
    </div>
    </>
  );
}
