import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";
import {Helmet} from "react-helmet";

export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>PARC INDUSTRIEL OULED SALEH</title>
        <meta
            name="description"
            content="Le parc industriel de Ouled Salah (POS) s’étend sur une superficie de 32 hectares et dispose d’une capacité de 121 lots industriels locatifs dont 33 sont des bâtiments industriels prêts à l’emploi ; en plus de bâtiments de services dédiés"
        />
      </Helmet>
      <div className="otherPageBanner servicesBanner">
        <div className="container" data-aos="fade-down">
          <h6><span className="fw-bold">PARC INDUSTRIEL</span> <br /> OULED SALEH</h6>
        </div>
      </div>
      <AmazingExpertise />
    </>
  );
}
