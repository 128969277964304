import React, { useEffect } from "react";
import Slider from "react-slick";
import { Card,Col,Row } from "react-bootstrap";
import OurTeamImg from "../HomePage/OurTeamImg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Aos from "aos";
import "aos/dist/aos.css";
const settings = {
  dots: false,
  aroow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurTeam() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
    <div className="projects">
      <div className="container-fluid">
        <div className="titleFont">
          <h2 className="fw-bold text-center">Projets opérationnels</h2>
        </div>
        <div className="ourTeamMain">
          <Slider {...settings} className="row">
            {OurTeamImg.map((photo) => (
              <div key={photo.id}>
                <Col xl={12}>
                  <Card className="ourTeamCard" data-aos="zoom-in">
                    <div className="ourTeamImgBox beffect">
                      <div className="bimg">
                        <LazyLoadImage effect="blur"
                          src={photo.image}
                          alt={photo.title}
                          className="img-fluid mx-auto card-img"
                        />
                      </div>
                    </div>
                    <Card.Body className="projectBtn">
                      <Link to={photo.slug} className="text-decoration-none">
                        <Row>
                          <Col xs={10} lg={10} md={10} sm={10} >
                              <Card.Title>{photo.title}</Card.Title>
                          </Col>
                          <div className="col-md-2 col-2 justify-content-center align-items-center d-flex">
                                <i className="fa fa-angle-right iconMore"></i>
                          </div>
                        </Row>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
    </>
  );
}
