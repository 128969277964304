import React from 'react'

const Actionnariat = () => {
  return (
    <div className='Actionnariat'>
        <h4 className='text-gray'>Actionnariat de la société de gestion du parc industriel d’Ouled salah :</h4>
        <div>
            <div className='item bg-item-blue'>
                <span>
                La commune d’Ouled Salah :
                </span>
                <span>
                10%
                </span>
            </div>
            <div className='item'>
                <span>
                La compagnie d’assurance ATLANTA-SANAD :
                </span>
                <span>
                10%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                Crédit du Maroc :
                </span>
                <span>
                13%
                </span>
            </div>
            <div className='item'>
                <span>
                Bank of Africa :
                </span>
                <span>
                13%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                Banque Marocaine pour le commerce et l’industrie  BMCI: 
                </span>
                <span>
                13%
                </span>
            </div>
            <div className='item'>
                <span>
                Société générale Maroc :
                </span>
                <span>
                13%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                Banque centrale populaire :
                </span>
                <span>
                13%
                </span>
            </div>
            <div className='item'>
                <span>
                MEDZ :
                </span>
                <span>
                15%
                </span>
            </div>            
        </div>
    </div>
  )
}

export default Actionnariat