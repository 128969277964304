import team1 from '../../../assets/Images/projects/parc-industriel-de-bouskoura.webp';
import team2 from '../../../assets/Images/projects/parc-industriel-de-settat.webp';
import team3 from '../../../assets/Images/projects/parc-industriel-de-salah.webp';
import team4 from '../../../assets/Images/projects/parc-industriel-de-berrechid.webp';

export const OurTeamImg = [
  {
    id: 1,
    image: team1,
    title: "PARC INDUSTRIEL DE BOUSKOURA",
    slug:"parc-industriel-de-bouskoura"
  },
  {
    id: 3,
    image: team3,
    title: "PARC INDUSTRIEL OULED SALAH",
    slug:"parc-industriel-de-ouled-saleh"
  },
  {
    id: 2,
    image: team2,
    title: "PARC INDUSTRIEL DE SETTAT", 
    slug:"parc-industriel-de-settat"
  },
  {
    id: 4,
    image: team4,
    title: "PARC ECOLOGIQUE DE BERRECHID",
    slug:"parc-ecologique-de-berrechid"
  },
];
export default OurTeamImg;