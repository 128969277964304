import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import blog2 from "../../../assets/Images/evenements/event 2.jpg";
import blog3 from "../../../assets/Images/evenements/vacicnation.jpeg";
import blog4 from '../../../assets/Images/evenements/vacicnationblog1.webp'
import blog5 from '../../../assets/Images/evenements/vacicnationblog2.webp'
import '../../../theme/css/App.css'
export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="">
          <Row>
            <Col xl={9} lg={9} md={9} sm={9} className="mx-auto media evenements">
              <div className="mediaVideo mt-5">
                  <img effect="blur"
                    src={blog3}
                    className="img-fluid mx-auto card-img"
                  />
              </div>
              <div className="content mt-5">
              <p className="text-secondary text-justify">
              <i className="fa fa-calendar-plus-o me-2" aria-hidden="true"></i>
                 02 Juin 2022 
              </p>
              <div className="hr"></div>
              <p className=" fs-5 fw-bold title_evenements">
              L'organisation de la campagne de vaccination en partenariat avec le 
                 Centre de vaccination de Berrechid.
              </p>
                <p className="text-secondary text-justify">
                    Depuis l'entrée en vigueur des vaccins contre la COVID-19, Partout dans le monde, des pays se mobilisent pour se procurer des vaccins et assurer l'immunisation de leurs populations en réaction à la pandémie, en l'occurrence, les parcs industriels CFCIM ont organisé 2 compagnes de vaccination par site en partenariat avec le centre de vaccination de Berrechid et de Nouaceur. 
                    Dans 
                </p>
                <Row>
                  <Col md={4}>
                    <p className="text-secondary text-justify">
                    l'intérêt des entreprises des Parcs. Cette intervention a été réalisée afin de faciliter la lutte contre le coronavirus et donner l'accès aux services de vaccination pour tous les employés et d'atteindre l'immunité collective.
                    </p>
                  </Col>
                  <Col md={4}>
                    <img src={blog4}  className="img-fluid mx-auto card-img" />
                  </Col>
                  <Col md={4}>
                    <img src={blog5}  className="img-fluid mx-auto card-img" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={9} lg={9} md={9} sm={9} className="mx-auto media evenements mt-5">
              <div className="mediaVideo mt-5">
              <Card className="ourTeamCard" data-aos="zoom-in">
                <div className="ourTeamImgBox beffect">
                  <div className="bimg">
                    <img effect="blur"
                      src={blog2}
                      className="img-fluid mx-auto card-img"
                    />
                  </div>
                </div>
              </Card>
              </div>
              <div className="content mt-5">
              <p className="text-secondary text-justify">
              <i className="fa fa-calendar-plus-o me-2" aria-hidden="true"></i>
                 14 Mai 2022 
              </p>
              <div className="hr"></div>
              <p className=" fs-5 fw-bold title_evenements"> 
              Présence au salon Global Industrie à Paris
              </p>
                <p className="text-secondary text-justify mb-5 pb-5">
                    La présence de la CFCIM a connu un grand succès lors du salon Global Industrie tenu à Paris qui a rassemblé 2300 exposants sur 100 000m² couvrant l’ensemble de l’écosystème industriel. 
                    La CFCIM se différencie des autres CCI par son offre des parcs industriels, qu’elle propose aux entreprises intéressées par le marché marocain qui regorge d’atouts indéniables, ses services d’accompagnement et son offre locative des espaces d’accueils industriels.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
