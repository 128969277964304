import React from 'react'

const Actionnariat = () => {
  return (
    <div className='Actionnariat'>
        <h4 className='text-gray text-justify'>Actionnariat de la société de gestion et d’exploitation du Parc industriel de Settat :</h4>
        <div>
            {/* 
       $






*/}
            <div className='item bg-item-blue'>
                <span>
            Crédit du Maroc 
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item'>
                <span>
                ARGANE INVEST 
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                ATTIJARIWAFABANK 
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item'>
                <span>
                Société générale Maroc
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                Banque Marocaine de commerce et d’industrie
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item'>
                <span>
                Municipalité de Settat
                </span>
                <span>
                17%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                    La Chambre de commerce, d’industrie et de services de
                    Casa-Settat 
                </span>
                <span>
                    1%
                </span>
            </div>
        </div>
    </div>
  )
}

export default Actionnariat