import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";
import {Helmet} from 'react-helmet'
export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>PARC INDUSTRIEL DE BOUSKOURA</title>
        <meta
            name="description"
            content="Premier parc développé par la CFCIM, le Parc Industriel de Bouskoura (PIB) –s’étale sur une surface"
        />
      </Helmet>
      <div className="otherPageBanner servicesBanner">
        <div className="container" data-aos="fade-down">
          <h6> <span className="fw-bold">PARC INDUSTRIEL</span> <br /> DE BOUSKOURA</h6>
        </div>
      </div>
      <AmazingExpertise />
    </>
  );
}
