import React from "react";
import NotreOffre from "../../../assets/Images/NotreOffre/1-2-min-1536x1485.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Row, Col, Accordion } from "react-bootstrap";
function HomeFAQ() {
  return (
    <>
      <div className="container" id="NotreOffre">
        <div className="spacing">
          <Row className="homeFAQRow">
          <Col xl={6} lg={6} md={6}>
              <div className="titleFont">
                <h2 className="fw-bold fs-1">Notre offre</h2>
                <p className="text-justify mt-5">
                La CFCIM a initié, en partenariat avec l’État marocain, 
                la création de parcs industriels à la location pour les entreprises. 
                Un projet ambitieux qui offre aux investisseurs des lots 
                de terrain et des bâtiments prêts à l’emploi.
                </p>
              </div>
              <div className="homeFAQ ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0" >
                    <Accordion.Header>
                      La location des terrains industriels
                    </Accordion.Header>
                    <Accordion.Body>
                        Des terrains aménagés et viabilisés prêt à la location à partir 5dh/M².
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                       La location des batiments industriels clé en main
                    </Accordion.Header>
                    <Accordion.Body>
                      Des batiments prêts à l’emploi à usage industriel à partir de 40 dh/M².
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    L’assistance à la maitrise d’ouvrage et gestion deléguée des parcs industriels.
                    </Accordion.Header>
                    <Accordion.Body>
                    Des formules d’accompagnement dans toutes les phases de vos projets d’aménagement des espaces d’accueils ( industriel, logistique/ distribution/ pépinière d’entreprise…)
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          <Col xl={6} lg={6} md={6}>
              <LazyLoadImage effect="blur" className="img-fluid mx-auto activite-principale-img" alt="AboutUs" src={NotreOffre} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HomeFAQ;
