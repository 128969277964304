import * as React from 'react';
import {useState} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Row,Col} from 'react-bootstrap'

export default function InputWithIcon({ecoParc}) {
  const [lead,setLead]=useState({
    nom:"",
    prenom:"",
    phone:"",
    email:""
  })
  const [errors,setErrors]=useState({});
  const [success,setSuccess]=useState(null);
  const HandleChange=(e)=>{
    setLead({...lead,[e.target.name]:e.target.value})
  }
  const HandleSubmit=(e)=>{
    e.preventDefault();
    if(lead.nom == "") {
      return setErrors({errors,nom:true});
    }
    if(lead.prenom == "") {
      return setErrors({errors,prenom:true});
    }
    if(lead.phone == "") {
      return setErrors({errors,phone:true});
    }
    if(lead.email == "") {
      return setErrors({errors,societe:true});
    }
    e.target.reset()
    setErrors({});
    setSuccess('Votre candidature a été bien Engerister avec Succès')
    
  }
  return (
      <>
      <form onSubmit={HandleSubmit}>
        <input value={ecoParc} name="parc" type="text" className="d-none" />
        <Row>
          <h3 className='text-center fw-bold mb-3' style={{color:"#18436d"}}>Dossier de candidature</h3>
            <h6 className='text-success text-center'>
              {success}
            </h6>
          <Col md={6}>
          <TextField  error={errors.nom} helperText={errors.nom ? "Nom est Obligatoire" : ''} fullWidth onChange={HandleChange} name="nom" label="Nom" id="fullWidth" />
          </Col> 
          <Col md={6}>
            <TextField error={errors.prenom} helperText={errors.prenom ? "Prénom est Obligatoire" : ''} fullWidth onChange={HandleChange} name="prenom" label="Prénom" />
          </Col>
          <Col md={12} style={{paddingTop:"24px"}}>
              <TextField error={errors.phone} helperText={errors.phone ? "Télephone est Obligatoire" : ''}  fullWidth onChange={HandleChange} name="phone" label="Téléphone"/>
          </Col> 
          <Col md={12} style={{paddingTop:"24px"}}>
              <TextField error={errors.societe} helperText={errors.societe ? "Email est Obligatoire" : ''} fullWidth  onChange={HandleChange} name="email" label="Email" />
          </Col>
          <div className="text-center">
            <Button 
            variant="contained" 
            type="submit"
            style={{marginTop:"24px",padding:"10px 30px",backgroundColor:"#e30044"}}
            >
              Envoyer
            </Button>
          </div>
        </Row>
      </form>
      </>
  );
}