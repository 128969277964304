import React from "react";
import { Row, Col } from "react-bootstrap";
import AmazingEx from "../../../assets/Images/Berrechid/PLAN-Damenagement-1536x676-1.png";
import logo from "../../../assets/Images/Berrechid/logo-2.png";
import References from "./References";
import More from "./More";
import Actionnariat from "./Actionnariat";
import Maps from "./Maps";
import Links from "../../Links";
import PlaquetteEcoparc from '../../../assets/pdf/Plaquette-Ecoparc-1.pdf'
import CAHIERDESCHARGES from '../../../assets/pdf/CAHIER-DES-CHARGES-PRENEUR-ECOPARC1.pdf'
import CAHIERDESCHARGESARCHITECTURA from '../../../assets/pdf/CAHIER-DES-CHARGES-ARCHITECTURAL-2.pdf'
import CONTRAT from '../../../assets/pdf/MODELE-CONTRAT-DE-BAIL-TERRAINS-ECOPARC1.pdf'
import Form from '../../Form';
export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4}>
              <Links />
              <a className="linkParc downloadBlue" href={PlaquetteEcoparc} target="_blank" rel="noreferrer">
                    Plaquette commerciale  
                  <i class="fa fa-download icon"></i>
              </a>
              <a className="linkParc downloadBlue" href={CAHIERDESCHARGES} target="_blank" rel="noreferrer">
                  Cahier des charges  
                  <i class="fa fa-download icon"></i>
              </a>
              <a className="linkParc downloadBlue" href={CAHIERDESCHARGESARCHITECTURA} target="_blank" rel="noreferrer">
                  Cahier des charges architectural
                  <i class="fa fa-download icon"></i>
              </a>
              <a className="linkParc downloadBlue" href={CONTRAT} target="_blank" rel="noreferrer">
                  Contrat de bail 
                  <i class="fa fa-download icon"></i>
              </a>
            </Col>
            <Col xl={8} lg={8} md={8} sm={8}>
              <div className="amazingColContain">
                <img src={AmazingEx} width="100%" alt="plan d'aménagement de berrechid" />
              </div>
              <div>
                <img src={logo} alt='parc-industriel-de-bouskoura' />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 titleparc">PARC INDUSTRIEL DE BERRECHID - ECOPARC</h5>
                <p className="text-justify">
                    Ce nouveau parc industriel, d’une superficie de 61 hectares, a une
                    capacité d’accueil de 192 entreprises. L’ECOPARC DE BERRECHID propose
                    des formules d’aménagement sur mesure permettant de s’adapter au
                    mieux aux besoins actuels et futurs de chaque entreprise, il offre des
                    terrains à construire et des bâtiments prêts à l’emploi en mode locatif.
                </p>
                <p className="text-justify">
                    Grâce à l’implication exemplaire de toutes les parties prenantes de ce
                    projet (partenaires gouvernementaux, collectivités, maitrise d’œuvre,
                    actionnaires…) l’Ecoparc de Berrechid est le premier parc industriel en
                </p>
                <p className="text-justify">
                Afrique à obtenir la certification HQE Aménagement (haute qualité environnementale)
                </p>
                <p className="text-justify">
                    Il a également été labellisé, dans le cadre de la COP22 organisée à
                    Marrakech en 2016.
                </p>
                <p className="text-justify">
                    Le Parc Industriel de Berrechid est géré par la société de gestion
                    ECOPARC DE BERRECHID sa : son capital est détenu par les actionnaires
                    ci-dessous
                </p>
              </div>
              <Actionnariat />
              <References />
              <Maps /> 
              <Form ecoParc="PARC INDUSTRIEL DE BERRECHID" /> 
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
