import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/icon/Un-reseaux-large.webp";
import Icon2 from "../../../assets/Images/icon/infra-100x100.webp";
import Icon3 from "../../../assets/Images/icon/Un-accompagnement-complet.webp";
import Icon4 from "../../../assets/Images/icon/emplacement-strategique.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Aos from "aos";
import "aos/dist/aos.css";
export default function WeProvide() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
    <div className="NosOffre">
      <div className="container">
        <div className="weProvide spacing">
          <div className="titleFont">
            <p className="text-center">
               <span className="fw-bold subtitleBerrechid text-uppercase mb-3 d-block">
                Une nouveauté pour les industriels ! 
                </span> 
                <br />
                Une formule complète 
                de location de foncier (terrains et bâtiments) incluant 
                de nombreux équipements et services <br />
                (salles de formation, bureaux, restaurants, hôtels, commerces, 
                guichet administratif unique…) 
                Vous est proposée à des tarifs compétitifs.
              </p>
          </div>
          <Row data-aos="zoom-in">
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <LazyLoadImage effect="blur"
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon1}
                  />
                </div>
                <div className="weProBoxText">
                  <p>SERVICES PRATIQUES</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <LazyLoadImage effect="blur"
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon2}
                  />
                </div>
                <div className="weProBoxText">
                  <p>INFRASTRUCTURES DE QUALITÉ</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <LazyLoadImage effect="blur"
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon3}
                  />
                </div>
                <div className="weProBoxText">
                  <p>UN ACCOMPAGNEMENT COMPLET</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="weProBox border-0">
                <div className="weProBoxImg">
                  <LazyLoadImage effect="blur"
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon4}
                  />
                </div>
                <div className="weProBoxText">
                  <p>EMPLACEMENT STRATÉGIQUE</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>  
    </>
  );
}
