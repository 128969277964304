import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Innovation from "./Innovation";
import Maps from "../HomePage/Maps";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner servicesBanner pageService">
        <div className="container" data-aos="fade-down">
          <p className="text-center subtitleBerrechid text-white">Nos Services</p>
          <h3 className="fw-bold mb-5 text-center text-white">DES SERVICES PRATIQUES ET UTILES <br /> POUR FACILITER VOTRE QUOTIDIEN</h3>
        </div>
      </div>
      <AmazingExpertise />
      <Innovation />
    </>
  );
}
