import React from "react";
import "../../../../src/theme/css/HomePage.css";
import Slide1 from "../../../assets/Images/Banner/Slider1.webp";
import Slide2 from "../../../assets/Images/Banner/Slider2.webp";
import Slide3 from "../../../assets/Images/Banner/Slider3.webp";
import Slide4 from "../../../assets/Images/Banner/Slider4.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slider from "react-slick";
const settings = {
  dots: false,
  arrows: false,
  fade: true,
  aroow: false,
  autoplay:true,
  infinite: true,
  speed: 500,
};
export default function Home() {
  return (
    <>
    
     <div className="slider">
        <Slider {...settings}>
        <div className="mainBannerDiv"  key="1">
            <LazyLoadImage 
            effect="blur" 
            className="img-fluid mx-auto" 
            src={Slide3} 
            width="100%" 
            alt="Les Parcs industriels CFCIM" 
            />
            <div className="container">
              <div className="bannerContain text-center" data-aos="zoom-in">
                <h1>Les Parcs industriels CFCIM</h1>
                <h5 className="fs-3 mt-0">
                  la référence en matiére de plateformes industrielles
                </h5>
              </div>
            </div>
          </div>
          <div className="mainBannerDiv" key="2">
            <LazyLoadImage 
            effect="blur" 
            className="img-fluid mx-auto" 
            src={Slide1} 
            width="100%" 
            alt="Les Parcs industriels CFCIM" 
            />
            <div className="container">
              <div className="bannerContain text-center" data-aos="zoom-in">
                <h1>Les Parcs industriels CFCIM</h1>
                <h5 className="fs-3 mt-0">
                  la référence en matiére de plateformes industrielles
                </h5>
              </div>
            </div>
          </div>
          <div className="mainBannerDiv" key="3">
            <LazyLoadImage 
            effect="blur" 
            className="img-fluid mx-auto" 
            src={Slide2} 
            width="100%" 
            alt="Les Parcs industriels CFCIM" 
            />
            <div className="container">
              <div className="bannerContain text-center" data-aos="zoom-in">
                <h1>Les Parcs industriels CFCIM</h1>
                <h5 className="fs-3 mt-0">
                  la référence en matiére de plateformes industrielles
                </h5>
              </div>
            </div>
          </div>
          <div className="mainBannerDiv" key="4">
            <LazyLoadImage 
            effect="blur" 
            className="img-fluid mx-auto" 
            src={Slide4} 
            width="100%" 
            alt="Les Parcs industriels CFCIM" 
            />
            <div className="container">
              <div className="bannerContain text-center" data-aos="zoom-in">
                <h1>Les Parcs industriels CFCIM</h1>
                <h5 className="fs-3 mt-0">
                  la référence en matiére de plateformes industrielles
                </h5>
              </div>
            </div>
          </div>
          
        </Slider>
     </div>
    </>
  );
}
