import React,{useEffect} from "react";
import AmazingExpertise from "./AmazingExpertise";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner servicesBanner">
        <div className="container" data-aos="fade-down">
          <h6> <span className="fw-bold">Parc en cours de développement </span></h6>
        </div>
      </div>
      <AmazingExpertise />
    </>
  );
}
