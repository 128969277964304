import React from "react";
import { Row, Col } from "react-bootstrap";
import Video from "./Video";

export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={8} lg={8} md={8} sm={8} className="mx-auto media">
              <div className="mediaVideo mt-5">
                <Video id="iLvUQXbYPLI" />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 titleparc tiltlemedia">Courses relai inter-entreprises :</h5>
                <p className="text-secondary text-justify">
                Les parcs industriels CFCIM assurent l’organisation de la course relai inter-entreprises entre les différentes entreprises des parcs industriels de la CFCIM, et ce dans un cadre convivial et une ambiance festive.
                </p>
              </div>
              <div className="mediaVideo mt-5">
                <Video id="W2K-Edflk_8" />
              </div>
              <div className="content mt-5">
              <h5 className="fw-bold fs-3 titleparc tiltlemedia">Tournoi mini foot  :</h5>
                <p className="text-secondary text-justify">
                Les parcs industriels CFCIM organisent un tournoi de mini-foot inter-entreprises entre les différentes entreprises des parcs CFCIM dans un climat informel et de détente propice à la connaissance et l’échange entre les employés.
                </p>
              </div>
              <div className="mediaVideo mt-5">
                <Video id="Fxk6L2MAFHo" />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 titleparc tiltlemedia">
                  Formation de luttre contre incendie et secourisme
                </h5>
                <p className="text-secondary text-justify">
                  Des formations utiles pour les industriels notamment la formation de lutte contre les incendies durant laquelle des démonstrations théoriques et pratiques concernant l'incendie (EPI) et le secourisme (SST) seront réalisées afin de permettre aux agents d’agir avec professionnalisme face à une situation d’accident et connaitre les notions élémentaires de prévention.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
