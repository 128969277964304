import React from 'react'
import { Row, Container,Col } from "react-bootstrap";

const LazyYoutube = () => {
  return (
    <Container>
    <h2 className='fw-bold text-center fs-1'>Parcs industriels de la CFCIM</h2>
    <p className="mt-5 text-center">Film institutionnel des parcs industriels de la CFCIM</p>
    <Row className="text-center">
        <Col md={9} className="mx-auto">
        <lite-youtube videoid="yWafq2zTWPg" autoload  params="controls=0&enablejsapi=1" className="w-100"></lite-youtube>
        </Col>
    </Row>
</Container>
  )
}

export default LazyYoutube