import React from "react";
import Slider from "react-slick";
import { Card, Col } from "react-bootstrap";
import OurBlogData from "./OurBlogData";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurBlog() {
  return (
    <>
      <div className="container">
        <div className="ourBlog spacing">
          <div className="titleFont">
            <h2 className="fw-bold text-center">Actualités</h2>
          </div>
          <div>
            <Slider {...settings} className="row">
              {OurBlogData.map((blog) => (
                <Col xl={4}  key={blog.id}>
                  <div className="ourBlogCard" data-aos="fade-up">
                    <Card>
                      <LazyLoadImage effect="blur"
                        className="img-fluid mx-auto card-img"
                        variant="top"
                        src={blog.image}
                        alt={blog.title}
                      />

                      <Card.Body>
                        <div className="blogCardBody">
                          <div className="d-inline ourBlogContain">
                            <span>{blog.date}</span>
                          </div>
                          <hr />
                          <Card.Title>
                            <Link to="/planing">{blog.title}</Link>
                          </Card.Title>
                          <Card.Text className="paragraph">
                            {blog.contain}
                          </Card.Text>
                          <button className="button buttonBlog">
                            <Link to="/evenements" >Voir Plus</Link>
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
