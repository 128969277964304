import React from 'react'

const Actionnariat = () => {
  return (
    <div className='Actionnariat'>
        <h4 className='text-gray'>Actionnariat de la société de gestion SOGEPIB :</h4>
        <div>
            <div className='item bg-item-blue'>
                <span>
                La commune rurale de Bouskoura
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item'>
                <span>
                La compagnie d’assurance – RMA WATANYA :
                </span>
                <span>
                25%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                La compagnie d’assurance SAHAM :
                </span>
                <span>
                14%
                </span>
            </div>
            <div className='item'>
                <span>
                La caisse de dépôt et de consignations- CDC : 
                </span>
                <span>
                10%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                La caisse de dépôt et de gestion- CDG :
                </span>
                <span>
                10%
                </span>
            </div>
            <div className='item'>
                <span>
                La compagnie d’assurance ATLANTA-SANAD :
                </span>
                <span>
                10%
                </span>
            </div>
            <div className='item bg-item-blue'>
                <span>
                La banque Marocaine de commerce et d’industrie- BMCI :
                La SANAD
                </span>
                <span>
                5%
                </span>
            </div>
            <div className='item'>
                <span>
                La société générale Marocaine de banque- SGMB :
                </span>
                <span>
                5%
                </span>
            </div>
        </div>
    </div>
  )
}

export default Actionnariat