import React from 'react'

const Maps = () => {
  return (
    <div>
      <iframe title="Location Media" src="https://www.google.com/maps/d/embed?mid=1ydU0jq5dCoNS84gHLkXRKAW5BI_83iQ5&ehbc=2E312F" width="100%" height="480"></iframe>
    </div>
  )
}

export default Maps