import blog1 from "../../../assets/Images/Blog/ECO-1.jpg";
import blog3 from "../../../assets/Images/Blog/eco-3.jpg";
import blog2 from "../../../assets/Images/evenements/event 2.jpg";

export const OurBlogData= [
      {
        id: 6,
        image: blog2,
        date: "14 Mai 2022",
        title: "La présence de la CFCIM a connu un grand succès lors du salon Global ",              
      },
      {
        id: 7,
        image: blog3,
        date: "01 Avril 2022",
        title: `Depuis l'entrée en vigueur des vaccins contre la COVID-19` 
      },
 
];
export default OurBlogData;