import React from 'react'
import { Link } from 'react-router-dom'
const Links = () => {
  return (
    <div>
    <Link className="linkParc" to="/parc-industriel-de-bouskoura">
      Parc industriel de Bouskoura
      <i class="fa fa-plus"></i>
    </Link>
    <Link className="linkParc" to="/parc-industriel-de-ouled-saleh">
      Parc industriel d’Ouled salah 
      <i class="fa fa-plus"></i>
    </Link>
    <Link className="linkParc" to="/parc-industriel-de-settat">
      Parc industriel de Settat 
      <i class="fa fa-plus"></i>
    </Link>
    <Link className="linkParc" to="/parc-ecologique-de-berrechid">
    Parc industriel de Berrechid  
      <i class="fa fa-plus"></i>
    </Link>
  </div>
  )
}

export default Links