import React from 'react'

const Maps = () => {
  return (
    <div>
        <iframe title="Map Ecoparc" src="https://www.google.com/maps/d/embed?mid=1_U7nKTokZUqbTVGSJWryygx5yhzwtGBq&hl=fr&ehbc=2E312F" width="100%" height="480"></iframe>
    </div>
  )
}

export default Maps