import React from 'react'

const Maps = () => {
  return (
    <div>
      <iframe title="location Ecoparc Berrchid" src="https://www.google.com/maps/d/embed?mid=18ItsyLg9cAYbd7D9FHxeQtfpiMVina2T&ehbc=2E312F" width="100%" height="480"></iframe>          
    </div>
  )
}

export default Maps