import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/pages/HomePage/Home";
import Bouskoura from "../components/pages/Bouskoura";
import Settat from "../components/pages/Settat";
import OuledSaleh from "../components/pages/OuledSaleh";
import Berrechid from "../components/pages/Berrechid";
import NosServices from "../components/pages/Service/Service"
import Media from "../components/pages/media"
import Evenements from "../components/pages/evenements"
import FuturParcs from "../components/pages/futurParcs"



export default function Index() {
 
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="parc-industriel-de-bouskoura" element={<Bouskoura />} />
        <Route path="parc-industriel-de-settat" element={<Settat />} />
        <Route path="parc-industriel-de-ouled-saleh" element={<OuledSaleh />} />
        <Route path="parc-ecologique-de-berrechid" element={<Berrechid />} />
        <Route path="nos-services" element={<NosServices />} />
        <Route path="media" element={<Media />} />
        <Route path="futurs-parcs" element={<FuturParcs />} />
        <Route path="/evenements" element={<Evenements />} />
      </Routes>
     </div>
  );
}
