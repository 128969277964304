import React from 'react'

const Actionnariat = () => {
  return (
    <div className='Actionnariat'>
        <div>
            <div className='item bg-item-blue'>
                <span>
            Commue rurale de sidi El Mekki :
                </span>
                <span>
                5,5%
                </span>
            </div>
            <div className='item'>
                <span>
                BANK OF AFRICA :
                </span>
                <span>
                21%
                </span>
            </div>
            <div className='item  bg-item-blue'>
                <span>
                ATTIJARI Capital development:
                </span>
                <span>
                18%
                </span>
            </div>
            <div className='item'>
                <span>
                Société Générale Maroc:
                </span>
                <span>
                20%

                </span>
            </div>
            <div className='item  bg-item-blue'>
                <span>
                Credit du Maroc:
                </span>
                <span>
                16%
                </span>
            </div>
            <div className='item'>
                <span>
                Banque Marocaine de commerce et d’industrie:
                </span>
                <span>
                19,5%

                </span>
            </div>
        </div>
    </div>
  )
}

export default Actionnariat