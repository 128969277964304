import React from "react";
import Banner from "../HomePage/Banner"
import OurTeam from "./OurTeam";
import OurBlog from "./OurBlog";
import Brandlogo from "./Brandlogo";
import StartedToday from "./StartedToday";
import WeProvide from "./WeProvide";
import ChooseUs from "./ChooseUs";
import HomeFAQ from "./HomeFAQ";
import PeopleSay from "./PeopleSay";
import Maps from "./Maps";
import LazyYoutube from "./LazyYoutube";
import "../../../theme/css/index.css";

export default function Home() {
  return (
    <>
      <Banner/>
      <HomeFAQ/>
      <WeProvide/>
      <PeopleSay/>
      <LazyYoutube  />
      <ChooseUs/>
      <OurTeam/>
      <OurBlog/>
      <Brandlogo/>
      <Maps/>
      <StartedToday/>  
    </>
  );
}
