import React from 'react'

const Maps = () => {
  return (
    <div>          
      <iframe title="Location Ouled Saleh" src="https://www.google.com/maps/d/embed?mid=1Y2BG9MPafHqmPH-zEVUdsgmiXbo8ss6J&ehbc=2E312F" width="100%" height="480"></iframe>
    </div>
  )
}

export default Maps