import React from "react";
import { Row, Col } from "react-bootstrap";
import AmazingEx from "../../../assets/Images/Settat/settatt-1536x1012.png";
import logo from "../../../assets/Images/Settat/SETTAT-PARK.png";
import References from "./References";
import Actionnariat from "./Actionnariat";
import Maps from "./Maps";
import Links from "../../Links";
import Form from '../../Form';
export default function AmazingExpertise() {
  return (
    <>
      <div className="container">
        <div className="amazingExpertise">
          <Row>
            <Col xl={4} lg={4} md={4} sm={4}>
             <Links />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8}>
              <div className="amazingColContain">
                <img src={AmazingEx} width="100%" alt="plan d'aménagement de settat" />
              </div>
              <div>
                <img src={logo} alt='parc-industriel-de-bouskoura' className="mt-5" width="40%" />
              </div>
              <div className="content mt-5">
                <h5 className="fw-bold fs-3 mt-5 titleparc">PARC INDUSTRIEL DE SETTAT</h5>
                <p className="text-justify">
                    S’étendant sur une superficie de 20 hectares, SETTAPARK dispose d’une
                    capacité d’accueil de 85 entreprises :
                </p>
                <p className="text-justify">
                    Équipé géré et sécurisé, SETTAPARK propose des lots de terrains de 500
                    à 4000 m2 à tarifs très compétitifs (formule de location longue durée)
                    Afin faciliter l’implantation des entreprises, tout en offrant un
                    accompagnement personnalisé à chaque étape.
                </p>
                <p className="text-justify">
                  Le Parc Industriel de Settat est géré par la société de gestion SETTAPARK
                  sa : son capital est détenu par les actionnaires suivants :
                </p>
              </div>
              <Actionnariat />
              <References />
              <Form  ecoParc="Setta Park"/> 
              <Maps />  
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
