import React from 'react'
import YouTube from 'react-youtube';

class Youtube extends React.Component {
  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      },
    };

    return  (
      <lite-youtube videoid={this.props.id} autoload  params="controls=0&enablejsapi=1" className="w-100"></lite-youtube>
    )

  }

}

export default Youtube