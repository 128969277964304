import React, { Component } from "react";
import Slider from "react-slick";
import BrandLogo1 from "../../../assets/Images/references/cfcim.png";
import BrandLogo2 from "../../../assets/Images/references/gov.png";
const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrow:false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,

      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,

      }
    },
    { 
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
};
export default class Brandlogo extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="brandLogo">
            <h3 className="fs-1 text-center fw-bold">Initié par</h3>
          <Slider {...settings}>
            <div>
              <img 
              className="img-fluid mx-auto" 
              src={BrandLogo1} 
              width="300px"
              height="169px"
              alt="Chambre Francaise de commerce et d'industrie du maroc" 
              />
            </div>
            <div>
              <img 
              className="img-fluid mx-auto" 
              src={BrandLogo2} 
              width="370px"
              height="59px"
              alt="Royaume du maroc ministre de l'industrie de commerce" 
              />
            </div>
          </Slider>
          </div>
        </div>
      </>
    );
  }
}
